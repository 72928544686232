/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

import $ from 'jquery';

// Make Bootstrap's JS available throughout the project
import 'bootstrap';
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

require('popper.js');
require('bootstrap-datepicker');

require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.nl-BE.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.da.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.fi.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.en-IE.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.nl.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.es.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.sv.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr-CH.min');
require('../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.en-GB.min');

// $(document).ready(function() {
//     $('[data-toggle="popover"]').popover();
// });

// Datepicker toggle
$(function () {
    // Show datepicker
    $('#datepicker-toggle').on('click', function () {
        $('#datepicker').removeClass('d-none');
        $('#datepicker-toggle > u').addClass('d-none');
    });

    // Initialize datepicker on div
    $('#js-datepicker').datepicker({
        format: 'yyyy-mm-dd',
        language: (typeof language !== 'undefined' ? language : ''),
        startDate: (typeof startDate !== 'undefined' ? startDate : ''),
        endDate: (typeof endDate !== 'undefined' ? endDate : ''),
        beforeShowDay: checkForAvailability
    });

    // Copy selected date to hidden field
    $('#js-datepicker').on('changeDate', function () {
        $('#schedule_appointment_user_selected_date').val(
            $('#js-datepicker').datepicker('getFormattedDate')
        );

        // Uncheck radio buttons
        $("input[name='schedule_appointment[preferred_dates]']").prop('checked', false);

        configureDateBlockFromDatepicker();
    });

    // Clear datepicker when a suggested date is selected
    $("input[name='schedule_appointment[preferred_dates]']").on('click', function () {
        $('#js-datepicker').val('').datepicker('update');

        // Set styling
        configureDateBlock(this);
    });

    if ($("input[name='schedule_appointment[parcel_type]']").length) {
        $("input[name='schedule_appointment[parcel_type]']").on('click', function () {
            if ($("input[name='schedule_appointment[preferred_dates]']").is(':checked')) {
                configureDateBlock($("input[name='schedule_appointment[preferred_dates]']"));
            } else {
                configureDateBlockFromDatepicker();
            }
        });
    }

    // Show password toggle
    $("#show_hide_password a").on('click', function (event) {
        event.preventDefault();
        if ($('#show_hide_password input').attr('type') == 'text') {
            $('#show_hide_password input').attr('type', 'password');
            $('#show_hide_password i').addClass('fa-eye-slash');
            $('#show_hide_password i').removeClass('fa-eye');
        } else if ($('#show_hide_password input').attr('type') == 'password') {
            $('#show_hide_password input').attr('type', 'text');
            $('#show_hide_password i').removeClass('fa-eye-slash');
            $('#show_hide_password i').addClass('fa-eye');
        }
    });

    $(".clickable-row").on('click', function () {
        window.location = $(this).data("href");
    });

    function configureDateBlockFromDatepicker() {
        configureDate($('#js-datepicker').datepicker('getFormattedDate'));
    }

    function configureDateBlock(element) {
        configureDate($(element).val());
    }

    function configureDate(date) {
        $('.appointment-date:not(.d-none)').addClass('d-none');

        if ($("input[name='schedule_appointment[parcel_type]']").length) {
            if ($("input[name='schedule_appointment[parcel_type]']:checked").val() == 0) {
                $('.swap-date[data-pickup-date="' + date + '"]').removeClass('d-none');
            } else {
                $('.dropoff-date[data-pickup-date="' + date + '"]').removeClass('d-none');
                $('.pickup-date[data-pickup-date="' + date + '"]').removeClass('d-none');
            }
        } else {
            $('.dropoff-date[data-pickup-date="' + date + '"]').removeClass('d-none');
            $('.pickup-date[data-pickup-date="' + date + '"]').removeClass('d-none');
        }

        $('.estimated-service-date[data-pickup-date="' + date + '"]').removeClass('d-none');
        $('.service-summary').removeClass('d-none');
    }

    // Check datepicker date for availability
    function checkForAvailability(date) {
        var dmy = date.getFullYear() + '-' + (zeroFill(date.getMonth() + 1)) + '-' + zeroFill(date.getDate());
        // var blockedDates is set in Twig template
        if (blockedDates.indexOf(dmy) != -1) {
            return false;
        }
        return true;
    }

    // Add prefix zero
    function zeroFill(i) {
        return (i < 10 ? '0' : '') + i;
    }

    // Disable submit buttons after form submission
    const forms = document.querySelectorAll('form');
    forms.forEach((form) => {
        let buttons = form.querySelectorAll('button, input[type="submit"], input[type="button"], input[type="reset"]');

        // Added field validation event listener
        let fields = form.querySelectorAll('[required], [aria-required]');
        let formValid = true;
        fields.forEach(field => {
            field.addEventListener('invalid', function (event) {
                // A field is invalid
                formValid = false;
            });
        });

        buttons.forEach((button) => {
            button.addEventListener('click', function (event) {
                setTimeout(function () {
                    // Form is invalid, don't disable the button
                    if (!formValid) {
                        // Reset the bool after the click, else the button won't be disabled after correct validation
                        formValid = true;
                        return;
                    }

                    button.classList.add('disabled');
                    event.target.disabled = true;
                    if (event.type === 'button') {
                        event.submit();
                    }

                }, 0);
            });
        });
    });

    // Find link buttons in Timeline and disable after first click
    const timelines = document.querySelectorAll('.timeline-body');
    timelines.forEach(timeline => {
        let links = timeline.querySelectorAll('a, .btn');

        links.forEach(link => {
            link.addEventListener('click', function (event) {
                link.classList.add('disabled');
                link.setAttribute('disabled', 'disabled');
            });
        });
    });

});

document.addEventListener('DOMContentLoaded', () => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
})